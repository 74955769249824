@import "../../styles/_chunk";

.checkout-trust-pilot-section {
  margin-bottom: 2rem;

  .trust-pilot-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-bottom: 2rem;
    }

    .image-container {
      width: 250px;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-right: 0.75rem;
        width: 260px;
        height: 30px;
      }

      @include media-breakpoint-up(lg) {
        width: 280px;
      }

      @include media-breakpoint-up(xl) {
        width: 295px;
        height: 34px;
      }

      @include media-breakpoint-up(xxl) {
        width: 310px;
        height: 36px;
      }
    }

    .text-container {
      margin-top: 4px;
      font-weight: 400;
      color: $text-white;
      text-align: center;
      font-size: 0.9375rem;
      margin-bottom: 0;

      @include theme(light) {
        color: map-get($light-theme, font-color);
      }

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
  }

  .trust-pilot-reviews-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    // REVIEW BOX

    .trust-pilot-review-box {
      background-color: $card-background-dark;
      border-radius: $border-radius-sm;
      width: 100%;
      padding: 1rem;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;

      @include theme(light) {
        background-color: map-get($light-theme, card-background);
        box-shadow: map-get($light-theme, card-box-shadow);
      }

      @include media-breakpoint-up(md) {
        width: 32%;
        margin-bottom: 0;
      }

      .trust-pilot-box-stars {
        display: flex;
        margin-bottom: 0.75rem;

        img {
          max-width: 100%;
          width: 100px;
          height: 19px;
        }
      }

      .trust-pilot-box-title {
        font-weight: 700;
        font-size: 1.125rem;
        margin-bottom: 0.75rem;
      }

      .trust-pilot-box-review {
        font-size: 0.8125rem;
        margin-bottom: 0.75rem;
      }

      .trust-pilot-box-verified {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: $grey-5;
        margin-top: auto;

        svg {
          margin-right: 0.5rem;
        }
      }

      &.review-three {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }
}
